.annotation {
  pointer-events: none;
  /* width: 320px; */
  font-size: 14px;
  display: block;
}

.annotation.attached {
  background: rgba(0, 0, 0, 0.66);
  padding: 16px 16px 8px;
  position: absolute;
  top: 20px;
}

.annotation .card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  /* min-height: 240px; */
  align-items: center;
  display: flex;
  flex-direction: column;
}

.annotation .card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.annotation .description {
  padding: 4px 2px;
}
