@keyframes emergency-light {
  0%,
  100% {
    background-color: red;
    box-shadow: 0 0 20px 10px red;
  }
  25%,
  75% {
    background-color: #333;
    box-shadow: none;
  }
  50% {
    background-color: red;
    box-shadow: 0 0 20px 10px red;
  }
}

.emergency-light {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  animation: emergency-light 2s infinite;
}
