html,
body,
#root {
  height: 100%;
  margin: 0;
  /* background: #000000; */
  /* background-image: linear-gradient(to top right, #000000, #535760); */
  background-image: url(./assets/background.svg);
  background-size: cover;
  color: #ffffff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

svg text {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input::-ms-reveal {
  display: none;
}

input::-webkit-contacts-auto-fill-button {
  display: none;
}

input::-webkit-credentials-auto-fill-button {
  display: none;
}

input::-webkit-eye {
  display: none;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(100, 116, 139, 0.5) rgba(100, 116, 139, 0.16);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

*::-webkit-scrollbar-track {
  background: rgba(100, 116, 139, 0.16);
  border-radius: 0.5rem;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(100, 116, 139, 0.5);
  border-radius: 0.25rem;
  border: 0.5rem solid rgba(100, 116, 139, 0.16);
}

@font-face {
  font-family: "Digital";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local("Digital"), local("Digital-Regular"),
    url("./fonts/Digital/Digital-Regular.ttf") format("truetype");
}

/* @keyframes emergency-light {
  0%,
  100% {
    background-color: red;
    box-shadow: 0 0 20px 10px red;
  }
  25%,
  75% {
    background-color: #333;
    box-shadow: none;
  }
  50% {
    background-color: red;
    box-shadow: 0 0 20px 10px red;
  }
}

.emergency-light {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  animation: emergency-light 2s infinite;
} */
